import axios from "axios";
import router from "@/router";
import store from "@/store";

const baseUrlApi = process.env.VUE_APP_URL_API;

const baseURL = `${baseUrlApi}` + "/v1";

const service = axios.create({
  baseURL
});

// every request
service.interceptors.request.use(
    function (config) {
      const token = store.getters['authStore/getToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
);
// // every response
service.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response === undefined) {
        const errorServerMessage = "Terjadi Kesalahan pada Server API!!!";
        throw {
          message: errorServerMessage,
          data: {
            error: {
              status: 500,
              message: errorServerMessage,
            },
          },
        };
      }
      const errorStatus = error.response.status;
      const errorResponse = error.response;
      // if (errorStatus === 401) {
      //   // redirect homepage
      //   // router.replace({name: "Beranda"})
      // }
      if (errorStatus === 403) {
        // redirect forbidden
        router.replace({name: "Forbidden"})
      }
      if (errorStatus === 404) {
        // redirect not found page
        router.replace({name: "Not Found"})
      }
      // const errorMessage = errorResponse.data.error.message;\
      const errorMessage = errorResponse.data.message;
      errorResponse.message = errorMessage
      throw errorResponse;
    }
);

export default service;
