import axios from "@/axios";

// async function login(formData) {
//   const endpoint = "ldap-login";
//   return await axios.post(endpoint, formData);
// }

async function getMe() {
  // const endpoint = "ldap-me";
  // return await axios.get(endpoint);
  const endpoint = "miso";
  const { data } = await axios.get(`${process.env.VUE_APP_AUTH_CLIENT_GET_ME_URL}`, {
    params: {
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID
    }
  });

  return data;
}

async function logout() {
  const endpoint = "ldap-logout";
  return await axios.post(endpoint);
}

async function login(code) {
  const endpoint = "miso";
  // return await axios.get(endpoint);
  const { data } = await axios.get(`auth/${endpoint}-callback`, {
    params: {
      code,
      action: "authenticate"
    }
  });
  return data;
}

export default {
  login,
  getMe,
  logout
};
